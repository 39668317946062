import React, {useEffect} from 'react';
import { navigate } from 'gatsby';
//import Layout from './my_boxit/_layout';
import { Layout } from '../layout';
import SEO from '../components/seo';
import { Container } from '../components/container';
import { useIdentityContext } from 'react-netlify-identity';
import i18n from '../i18n';

const NotFoundPage = props => {
  const identity = useIdentityContext();
  const { isLoggedIn } = identity;

  useEffect(() => {
    if(!isLoggedIn){
      navigate((i18n.language === 'en')? `${'/'+i18n.language}/sign_in`: '/sign_in');
    }
  })

  return(
    <Layout {...props}>
      <SEO title="404: Not found" />
    </Layout>
)
};

export default NotFoundPage;
